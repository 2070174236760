import FACE from "../../assets/image/facebook-share.png";
import NEW1 from "../../assets/image/news.webp";
import WORK from "../../assets/image/work.png";
import TT from "../../assets/image/tt.png";
import NEW2 from "../../assets/image/news-2.png";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import MVC_1 from "../../assets/image/mvcpro/1.JPG";
import MVC_2 from "../../assets/image/mvcpro/2.JPG";
import MVC_3 from "../../assets/image/mvcpro/3.JPG";
import MVC_4 from "../../assets/image/mvcpro/4.JPG";
import MVC_5 from "../../assets/image/mvcpro/5.JPG";
import MVC_6 from "../../assets/image/mvcpro/6.JPG";
import MVC_7 from "../../assets/image/mvcpro/7.JPG";

export default function DetailsMVCPRO() {
  const navigate = useNavigate();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const handleNewDetail = (id) => {
    window.scrollTo(0, 0);
    navigate("/news-detail/" + id);
  };

  return (
    <div className="w-[90%] sm:w-[65%] min-[1024px]:w-[90%] min-[1700px]:w-[55%] min-[1300px]:w-[73%] relative content-main">
      <div className="mt-6">
        <div className="flex justify-between">
          <div className="text-gray-500 font-book text-[12px]">
            Thứ bảy, 15 tháng 11, 2024
          </div>
          <div>
            <div className="w-20">
              <a
                href="https://www.facebook.com/share.php?u=https://www.facebook.com/mvcpro.vn"
                target="_blank"
              >
                <img src={FACE} className="w-20 cursor-pointer" />
              </a>
            </div>
          </div>
        </div>
      </div>
      {/* <div className="mt-5 p-3 text-[13px] rounded-lg font-book shadow-grid"></div> */}
      <div className="mt-10">
        {/* {data?.img?.map((res, index) => {
          return (
            <div key={index}>
              <img src={res} className="mt-2 w-full" />
            </div>
          );
        })} */}
      </div>
      <div className="mt-10">
        <h3 className="font-bold mt-3">
          Hội Nghị Đổi Mới & Phát Triển 2024: Cột Mốc Gắn Kết Và Thúc Đẩy Đội
          Ngũ MVCPRO
        </h3>

        <img src={MVC_1} className="mt-5" />
        <p className="mt-2">
          Trong hai ngày 16 và 17/08/2024, MVCPRO đã tổ chức thành công Hội nghị
          Đổi mới & Phát triển, sự kiện đánh dấu cột mốc quan trọng trong hành
          trình gắn kết và nâng tầm đội ngũ công ty. Hội nghị thu hút 143 nhân
          viên thuộc khối dự án RO (Retail Operations) với hàng loạt hoạt động ý
          nghĩa như lắng nghe chia sẻ truyền cảm hứng từ lãnh đạo công ty, đào
          tạo và giao lưu.
        </p>

        <h3 className="font-bold mt-3">
          Đổi mới mỗi ngày, phát triển không ngừng & cùng nhau vươn xa
        </h3>
        <p className="mt-2">
          Với chủ đề “Đổi mới & Phát triển”, hội nghị được tổ chức nhằm thúc đẩy
          tư duy sáng tạo, tăng cường kết nối và khơi dậy động lực phát triển
          cho đội ngũ nhân sự của MVCPRO.
        </p>
        <p className="mt-2">
          Đây cũng là dịp để toàn thể nhân viên cùng nhìn lại chặng đường đã qua
          để càng thêm gắn bó với công ty hơn và nỗ lực trau dồi, thích ứng với
          những thay đổi của thực tiễn, tạo tiền đề cho sự đột phá trong tương
          lai.
        </p>
        <h3 className="font-bold mt-3">Những hoạt động nổi bật</h3>
        <p className="mt-2">
          Hội nghị mở đầu bằng các phiên chia sẻ về định hướng chiến lược năm
          mới từ Ban Lãnh đạo MVCPRO, nhằm giúp đội ngũ nắm bắt mục tiêu và
          chiến lược chung của công ty.
        </p>
        <p className="mt-2">
          Tiếp theo là chuỗi các hoạt động đào tạo tập trung vào những chủ đề
          cốt lõi, cung cấp kiến thức và kỹ năng giúp nhân viên nâng cao hiệu
          quả công việc. Từ đó, mỗi nhân viên sẽ có những định hướng phát triển
          cho sự nghiệp của bản thân trong tương lai.
        </p>
        <img src={MVC_2} className="mt-5" />
        <p className="mt-2">
          Tối ngày đầu tiên, chương trình Gala Dinner đã diễn ra trong không khí
          sôi động, gắn kết. Đây không chỉ là dịp để mọi người giao lưu, giải
          trí mà còn là cơ hội để vinh danh những đóng góp nổi bật của các cá
          nhân và tập thể.
        </p>
        <img src={MVC_3} className="mt-5" />
        <p className="mt-2">
          Bước sang ngày thứ hai, đội ngũ MVCPRO cùng tham gia các hoạt động
          Team Building sáng tạo và đầy thử thách. Các trò chơi được thiết kế
          nhằm khơi dậy tinh thần đồng đội, kỹ năng phối hợp và sự sáng tạo của
          từng thành viên. Những tiếng cười, cái siết tay thật chặt và những
          khoảnh khắc đáng nhớ đã trở thành dấu ấn không thể quên trong chương
          trình.
        </p>
        <img src={MVC_4} className="mt-5" />
        <img src={MVC_5} className="mt-5" />
        <img src={MVC_6} className="mt-5" />
        <img src={MVC_7} className="mt-5" />
        <p className="mt-2 italic">
          Hội nghị Đổi mới & Phát triển đã khẳng định cam kết của MVCPRO trong
          việc đầu tư vào đội ngũ nhân sự, đặt trọng tâm vào sự đổi mới, đoàn
          kết và phát triển bền vững. Tinh thần năng động, tích cực từ hội nghị
          sẽ là nguồn động lực to lớn để tập thể MVCPRO cùng nhau chinh phục
          những thành công mới trong những năm về sau.
        </p>
        {/* {data?.content?.map((res, index) => {
          return ( */}
        {/* <div key={index}>
              <h3 className="font-bold mt-3">{res?.content_title}</h3>
              <p className="mt-1">{res?.content_description}</p>
            </div> */}
        {/* );
        })} */}
      </div>

      <div className="flex mt-10">
        <div className="mr-2 flex items-center">
          <img src={WORK} className="w-5" />
        </div>
        <div className="font-regular text-[#3AA3A4] text-[24px]">
          BÀI VIẾT CÙNG CHUYÊN MỤC
        </div>
      </div>

      <div className="my-5 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8">
        <div
          className="col-span-1 p-2 shadow-grid rounded-lg hover:cursor-pointer"
          onClick={() =>
            handleNewDetail("mvc-to-chuc-chuong-trinh-huan-luyen-cho-nhan-vien")
          }
        >
          <img src={TT} className="rounded-md repon-img w-full max-h-[194px]" />
          <div className="text-[16px] text-reponsive mt-2 font-regular">
            MVCPRO tổ chức chương trình huấn luyện cho Nhân viên bán hàng
          </div>
          <p className="text-[14px] text-reponsive mt-2 font-book">
            Những Nữ chiến binh bán hàng của MVCPRO đã có những trải nghiệm
            tuyệt vời tại buổi Training Kỹ Năng đọc vị tâm lý khách hàng và chốt
            sale ...
          </p>
          <div className="hover:cursor-pointer text-[13px] mt-2 font-book text-[#3AA3A4]">
            Xem thêm {">"}
          </div>
        </div>

        <div
          className="col-span-1 p-2 shadow-grid rounded-lg hover:cursor-pointer"
          onClick={() =>
            handleNewDetail("mvc-cung-pg-to-chuc-tap-huan-nhan-vien")
          }
        >
          <img
            src={NEW1}
            className="rounded-md w-full repon-img max-h-[194px]"
          />
          <div className="text-[15px] text-reponsive mt-2 font-regular">
            MVCPRO cùng P&G tổ chức đợt tập huấn cho Nhân Viên Bán Hàng kênh
            Siêu thị hiện đại
          </div>
          <p className="text-[14px] text-reponsive mt-2 font-book">
            MVCPRO hợp tác cùng P&G tổ chức chương trình tập huấn Nhân Viên Bán
            Hàng kênh Siêu thị hiện đại. Đợt training này diễn ra với sự tham
            gia sôi nổi của hơn 200 nhân viên bán hàng dưới sự chỉ đạo và huấn
            luyện của những ...
          </p>
          <div className="hover:cursor-pointer text-[13px] mt-2 font-book text-[#3AA3A4]">
            Xem thêm {">"}
          </div>
        </div>
        <div
          className="col-span-1 p-2 shadow-grid rounded-lg hover:cursor-pointer"
          onClick={() =>
            handleNewDetail("meo-giup-de-dang-trung-tuyen-phong-van")
          }
        >
          <img
            src={NEW2}
            className="rounded-md w-full repon-img max-h-[194px]"
          />
          <div className="text-[15px] text-reponsive mt-2 font-regular">
            MVCPRO tổ chức chương trình huấn luyện cho Nhân viên bán hàng
          </div>
          <p className="text-[14px] text-reponsive mt-2 font-book">
            Những Nữ chiến binh bán hàng của MVCPRO đã có những trải nghiệm
            tuyệt vời tại buổi Training Kỹ Năng đọc vị tâm lý khách hàng và chốt
            sale...
          </p>
          <div className="hover:cursor-pointer text-[13px] mt-2 font-book text-[#3AA3A4]">
            Xem thêm {">"}
          </div>
        </div>
      </div>
    </div>
  );
}
