import Header from "../../components/Header";
import Footer from "../../components/FooterNavBar/FooterNavBar";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import ToggleSmooth from "../../components/NavSearch/togglesmooth/togglesmooth";
import NewsItem from "./NewsItem";
import { dataRecruitmentNews } from "../../utils/data";
import DetailsMVCPRO from "./DetailsMVCPRO";

export default function NewDetailsMVCPRO() {
  const { id } = useParams();
  const dataFilter = dataRecruitmentNews.filter((x) => x.id === id);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <div className="lg:mt-20 mt-14">
        <Header
          title={
            "Hội Nghị Đổi Mới & Phát Triển 2024: Cột Mốc Gắn Kết Và Thúc Đẩy Đội Ngũ MVCPRO"
          }
        />
      </div>
      <DetailsMVCPRO />
      <div className="bg-footer mt-16">
        <Footer />
      </div>
      <ToggleSmooth />
    </div>
  );
}
