import { useNavigate } from "react-router-dom";
import BD from "../../../assets/image/bd1.jpg";
import BD3 from "../../../assets/image/bd3.jpg";
import WORK from "../../../assets/image/work.png";
import TT from "../../../assets/image/mvcpro/thumbnail.png";
import TT1 from "../../../assets/image/tt1.png";
import NEW1 from "../../../assets/image/new2.webp";

export default function NewsMainList() {
  const navigate = useNavigate();
  const handleNewDetail = (id) => {
    navigate("/news-detail/" + id);
  };
  const handleNewDetails = (id) => {
    navigate("/news-details/" + id);
  };
  return (
    <div className="w-[90%] min-[1700px]:w-[55%] sm:w-[65%] min-[1024px]:w-[90%] min-[1300px]:w-[73%] relative content-main my-8">
      <div className="flex">
        <div className="mr-2 flex items-center">
          <img src={WORK} className="w-5" />
        </div>
        <div className=" font-regular text-[#3AA3A4] text-[24px]">
          CÁC TIN TỨC MỚI NHẤT
        </div>
      </div>
      <div className="mt-5 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8">
        <div
          className="hover:cursor-pointer col-span-1 p-2 shadow-grid rounded-lg border-ani"
          onClick={() => handleNewDetails("hoi-nghi-doi-moi")}
        >
          <img
            src={TT}
            className="object-cover w-full rounded-md repon-img max-h-[230px]"
          />
          <div className="text-[15px] title-reponsive mt-2 font-regular">
            Hội Nghị Đổi Mới & Phát Triển 2024: Cột Mốc Gắn Kết Và Thúc Đẩy Đội
            Ngũ MVCPRO
          </div>
          <p className="text-[14px] text-reponsive mt-2 font-book">
            Trong hai ngày 16 và 17/08/2024, MVCPRO đã tổ chức thành công Hội
            nghị Đổi mới & Phát triển, sự kiện đánh dấu cột mốc quan trọng ...
          </p>
          <div className="hover:cursor-pointer text-[13px] mt-2 font-book text-[#3AA3A4]">
            Xem thêm {">"}
          </div>
        </div>

        <div
          className=" border-ani col-span-1 p-2 shadow-grid rounded-lg hover:cursor-pointer"
          onClick={() =>
            handleNewDetail("mvc-gan-ket-nhan-vien-qua-hoat-dong-team-building")
          }
        >
          <img
            src={TT1}
            className="rounded-md w-full repon-img max-h-[230px]"
          />
          <div className="text-[15px] title-reponsive mt-2 font-regular">
            MVC PRO gắn kết nhân viên thông qua hoạt động TeamBuilding đầy hứng
            thú
          </div>
          <p className="text-[14px] text-reponsive mt-2 font-book">
            Không phân biệt cấp bậc giữa lãnh đạo và nhân viên, không có khoảng
            cách giữa địa phương hay vùng miền, hơn 500 anh em nhân viên khối
            Siêu thị ...
          </p>
          <div className="hover:cursor-pointer text-[13px] mt-2 font-book text-[#3AA3A4]">
            Xem thêm {">"}
          </div>
        </div>
        <div className="min-[1024px]:col-span-1  min-[1024px]:grid min-[1024px]:grid-cols-1 min-[1024px]:gap-6 max-[1024px]:hidden">
          {/* <div
            onClick={() =>
              handleNewDetail("mvc-cung-pg-to-chuc-tap-huan-nhan-vien")
            }
            className=" border-ani col-span-1 shadow-grid mb-2 rounded-lg hover:cursor-pointer"
          >
            <div className="p-2 flex">
              <div className="flex items-center w-60">
                <img src={NEW1} className="w-60 max-h-[180px] rounded-md" />
              </div>
              <div className="px-2 flex flex-col justify-around">
                <div className="text-[15px] font-regular">
                  MVCPRO cùng P&G tổ chức đợt tập huấn cho Nhân Viên Bán Hàng
                  kênh Siêu thị hiện đại
                </div>
                <div className="text-[13px] font-book text-[#13a5a5]">
                  Xem thêm {">"}
                </div>
              </div>
            </div>
          </div> */}
          <div
            onClick={() =>
              handleNewDetail("mvc-cung-pg-to-chuc-tap-huan-nhan-vien")
            }
            className="border-ani col-span-1 shadow-grid mb-2 rounded-lg hover:cursor-pointer"
          >
            <div className="p-2 flex">
              <div className="flex items-center">
                <img src={NEW1} className="w-60 max-h-[180px] rounded-md" />
              </div>
              <div className="px-2 flex flex-col justify-around">
                <div className="text-[15px] font-regular">
                  MVCPRO cùng P&G tổ chức đợt tập huấn cho Nhân Viên ...
                </div>
                <div className="text-[13px] font-book text-[#13a5a5]">
                  Xem thêm {">"}
                </div>
              </div>
            </div>
          </div>
          <div
            onClick={() =>
              handleNewDetail("nhung-dieu-nen-trang-bi-trong-nhung-nam-dau")
            }
            className="border-ani col-span-1 shadow-grid mb-2 rounded-lg hover:cursor-pointer"
          >
            <div className="p-2 flex">
              <div className="flex items-center">
                <img src={BD3} className="w-60 max-h-[180px] rounded-md" />
              </div>
              <div className="px-2 flex flex-col justify-around">
                <div className="text-[15px] font-regular">
                  Những điều nên trang bị trong những năm đầu tiên đi làm
                </div>
                <div className="hover:cursor-pointer text-[13px] font-book text-[#13a5a5]">
                  Xem thêm {">"}
                </div>
              </div>
            </div>
          </div>

          <div
            onClick={() =>
              handleNewDetail("meo-giup-de-dang-trung-tuyen-phong-van")
            }
            className="border-ani col-span-1 shadow-grid mb-2 rounded-lg hover:cursor-pointer"
          >
            <div className="p-2 flex">
              <div className="flex items-center">
                <img src={BD} className="w-60 rounded-md" />
              </div>
              <div className="px-2 flex flex-col justify-around">
                <div className="text-[15px] font-regular">
                  5 mẹo để giúp bạn dễ dàng trúng tuyển phỏng vấn ...
                </div>
                <div className="hover:cursor-pointer text-[13px] font-book text-[#13a5a5]">
                  Xem thêm {">"}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="mt-5 grid grid-cols-1 gap-8 min-[1024px]:hidden">
        <div
          onClick={() =>
            handleNewDetail("meo-giup-de-dang-trung-tuyen-phong-van")
          }
          className="hover:cursor-pointer p-2 flex shadow-grid rounded-xl border-ani"
        >
          <div className="flex items-center">
            <img src={BD} className="h-28 w-[full] max-w-[200px] rounded-lg" />
          </div>
          <div className="px-2 flex flex-col justify-around">
            <div className="text-[15px]  font-regular">
              5 mẹo để giúp bạn dễ dàng trúng tuyển phỏng vấn ...
            </div>
            <div className="hover:cursor-pointer text-[13px] font-book text-[#13a5a5]">
              Xem thêm {">"}
            </div>
          </div>
        </div>
        <div
          onClick={() =>
            handleNewDetail("nhung-dieu-nen-trang-bi-trong-nhung-nam-dau")
          }
          className="hover:cursor-pointer p-2 flex shadow-grid rounded-xl border-ani"
        >
          <div className="flex items-center">
            <img src={BD3} className="h-28 w-[full] max-w-[200px] rounded-lg" />
          </div>
          <div className="px-2 flex flex-col justify-around">
            <div className="text-[15px] font-regular">
              Những điều nên trang bị trong những năm đầu tiên đi làm
            </div>
            <div className="hover:cursor-pointer text-[13px] font-book text-[#13a5a5]">
              Xem thêm {">"}
            </div>
          </div>
        </div>
        <div
          onClick={() =>
            handleNewDetail("mvc-cung-pg-to-chuc-tap-huan-nhan-vien")
          }
          className="hover:cursor-pointer p-2 flex shadow-grid rounded-xl border-ani"
        >
          <div className="flex items-center">
            <img
              src={NEW1}
              className="h-28 w-[full] max-w-[200px] rounded-lg"
            />
          </div>
          <div className="px-2 flex flex-col justify-around">
            <div className="text-[15px] font-regular">
              MVCPRO cùng P&G tổ chức đợt tập huấn cho Nhân Viên Bán Hàng kênh
              Siêu thị hiện đại
            </div>
            <div className="hover:cursor-pointer text-[13px] font-book text-[#13a5a5]">
              Xem thêm {">"}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
