import { useEffect, useState } from "react";
import Header from "../../components/Header";
import TT from "../../assets/image/tt.png";
import TT1 from "../../assets/image/tt1.png";
import Footer from "../../components/FooterNavBar/FooterNavBar";
import ToggleSmooth from "../../components/NavSearch/togglesmooth/togglesmooth";
import { MetaTags } from "react-meta-tags";
import { useNavigate } from "react-router-dom";
import NEW1 from "../../assets/image/news.webp";
import MVCPRO from "../../assets/image/mvcpro/thumbnail.png";
let title = "TIN TỨC - SỰ KIỆN";
export default function RecruitmentNewsList() {
  const [current, setCurrent] = useState("1");
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const hanldeClick = (e, status_gifts) => {
    setCurrent(e.target.id);
  };
  const navigate = useNavigate();

  const handleNewDetail = (id) => {
    window.scrollTo(0, 0);
    navigate("/news-detail/" + id);
  };
  const handleNewDetails = (id) => {
    window.scrollTo(0, 0);
    navigate("/news-details/" + id);
  };
  return (
    <div className="lg:mt-20 mt-16">
      <MetaTags>
        <title>Tin tức - Sự kiện</title>
      </MetaTags>
      <Header title={title} />
      <div className="mt-10 flex flex-col justify-center items-center px-5">
        <div className="bg-tab tab flex justify-between items-center align-middle w-full max-w-[600px] mx-5 mb-5 bg-tab">
          <div className="flex justify-center flex-auto">
            <button
              key="1"
              id={1}
              disabled={current === `${1}`}
              onClick={(e) => hanldeClick(e, "done")}
              className="font-book  text-[13px]"
            >
              Tin tức mới
            </button>
          </div>
          <div className="flex justify-center flex-auto">
            <button
              key="2"
              id={2}
              disabled={current === `${2}`}
              onClick={(e) => hanldeClick(e, "not_yet")}
              className="font-book  text-[13px]"
            >
              Cuộc sống MVC
            </button>
          </div>
          <div className="flex justify-center flex-auto">
            <button
              key="3"
              id={3}
              disabled={current === `${3}`}
              onClick={(e) => hanldeClick(e, "not_yet")}
              className="font-book  text-[13px]"
            >
              Cẩm nang nghề nghiệp
            </button>
          </div>
        </div>
      </div>
      <div className="w-[90%] sm:w-[95%] min-[1024px]:w-[95%] min-[1300px]:w-[100%] relative content-main my-0">
        <div className="lg:mx-20 2xl:mx-56 sm:mx-20 ">
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-5 mt-5">
            <div
              onClick={() =>
                handleNewDetail(
                  "mvc-to-chuc-chuong-trinh-huan-luyen-cho-nhan-vien"
                )
              }
              className="hover:cursor-pointer col-span-1 p-2 shadow-grid rounded-lg"
            >
              <img src={TT} className="rounded-md" />
              <div className="text-[16px] text-reponsive mt-2 font-regular">
                MVCPRO tổ chức chương trình huấn luyện cho Nhân viên bán hàng
              </div>
              <p className="text-[14px] text-reponsive mt-2 font-book">
                Những Nữ chiến binh bán hàng của MVCPRO đã có những trải nghiệm
                tuyệt vời tại buổi Training Kỹ Năng đọc vị tâm lý khách hàng và
                chốt sale...
              </p>
              <div className="hover:cursor-pointer text-[13px] mt-2 font-book text-[#3AA3A4]">
                Xem thêm {">"}
              </div>
            </div>
            <div
              onClick={() =>
                handleNewDetail(
                  "mvc-gan-ket-nhan-vien-qua-hoat-dong-team-building"
                )
              }
              className="hover:cursor-pointer col-span-1 p-2 shadow-grid rounded-lg"
            >
              <img src={TT1} className="rounded-md" />
              <div className="text-[15px] text-reponsive mt-2 font-regular">
                MVCPRO gắn kết nhân viên thông qua hoạt động Team Building đầy
                hứng thú
              </div>
              <p className="text-[14px] text-reponsive mt-2 font-book">
                Không phân biệt cấp bậc giữa lãnh đạo và nhân viên, không có
                khoảng cách giữa địa phương hay vùng miền, hơn 500 anh em nhân
                viên khối Siêu thị trên toàn ...
              </p>
              <div className="hover:cursor-pointer text-[13px] mt-2 font-book text-[#3AA3A4]">
                Xem thêm {">"}
              </div>
            </div>

            <div
              onClick={() =>
                handleNewDetail("mvc-cung-pg-to-chuc-tap-huan-nhan-vien")
              }
              className="hover:cursor-pointer col-span-1 p-2 shadow-grid rounded-lg"
            >
              <img src={NEW1} className="rounded-md" />
              <div className="text-[15px] text-reponsive mt-2 font-regular">
                MVCPRO cùng P&G tổ chức đợt tập huấn cho Nhân Viên Bán Hàng kênh
                Siêu thị hiện đại
              </div>
              <p className="text-[14px] text-reponsive mt-2 font-book">
                MVCPRO hợp tác cùng P&G tổ chức chương trình tập huấn Nhân Viên
                Bán Hàng kênh Siêu thị hiện đại. Đợt training này diễn ra với sự
                tham gia sôi nổi ...
              </p>
              <div className="hover:cursor-pointer text-[13px] mt-2 font-book text-[#3AA3A4]">
                Xem thêm {">"}
              </div>
            </div>
            <div
              onClick={() => handleNewDetails("hoi-nghi-doi-moi")}
              className="hover:cursor-pointer col-span-1 p-2 shadow-grid rounded-lg"
            >
              <img src={MVCPRO} className="rounded-md" />
              <div className="text-[15px] text-reponsive mt-2 font-regular">
                Hội Nghị Đổi Mới & Phát Triển 2024: Cột Mốc Gắn Kết Và Thúc Đẩy
                Đội Ngũ MVCPRO
              </div>
              <p className="text-[14px] text-reponsive mt-2 font-book">
                Trong hai ngày 16 và 17/08/2024, MVCPRO đã tổ chức thành công
                Hội nghị Đổi mới & Phát triển, sự kiện đánh dấu cột mốc quan
                trọng ...
              </p>
              <div className="hover:cursor-pointer text-[13px] mt-2 font-book text-[#3AA3A4]">
                Xem thêm {">"}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="bg-footer mt-16">
        <Footer />
      </div>
      <ToggleSmooth />
    </div>
  );
}
